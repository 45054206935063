<template>
    <div class="big">
        <div class="left">
            <div class="left-title">
                <input type="text" ref="inputGoods" v-model="goodsId" placeholder="请扫描或输入水洗唛编码">
                <button @click="searchOrder">查询</button>
            </div>
            <div :class="isTime ? 'left-content left-content-add' : 'left-content '" v-if="goodsList.length != 0">
                <p class="title">订单编号:</p>
                <p class="num">{{ goodsInfo.number }}</p>
                <p class="title">
                    订单状态:
                    <span v-if="this.goodsInfo.orderStatus == 20">已揽收送货工厂中</span>
                    <span v-if="this.goodsInfo.orderStatus == 22">工厂已收货</span>
                    <span v-if="this.goodsInfo.orderStatus == 23">工厂清洗中</span>
                    <span v-if="this.goodsInfo.orderStatus == 24">清洗完成</span>
                    <span v-if="this.goodsInfo.orderStatus == 30">派送中</span>
                    <span v-if="this.goodsInfo.orderStatus == 50">已完成</span>
                </p>
                <ul class="order-list ">
                    <li @click="checkGoods(index)"
                        :class="checkIndex == index ? 'order-item order-item-add' : 'order-item'"
                        v-for="(item, index) in goodsList" :key="index">
                        <p>商品ID:{{ item.id }}</p>
                        <p>商品名称:{{ item.title }}</p>
                    </li>
                </ul>
                <!-- <button class="order-btn">完成补录</button> -->
            </div>
            <div v-if="goodsList.length == 0">
                <el-empty description="暂无订单"></el-empty>
            </div>
        </div>
        <div class="right">
            <div v-if="showBigPhoto">
                <img style="position:absolute; left: 0; z-index: 3; width: 1700px; height: 850px;" :src="bigPhotoUrl"
                    alt="">
            </div>
            <div class="right-tabs">
                <el-tabs v-model="activeName" :stretch="true" type="border-card">
                    <el-tab-pane label="分类" name="one">
                        <div class="sort-title">
                            <ul>
                                <li :class="typeIndex == index ? 'sort-item sort-item-add' : 'sort-item '"
                                    @click="getType(index)" v-for="(item, index) in clothType">
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="cloth">
                            <ul v-if="typeIndex == 0">
                                <li @click="getGoods(item, index)"
                                    :class="goodsTypeIndex == index ? 'cloth-item cloth-item-add' : 'cloth-item'"
                                    v-for="(item, index) in jacket">
                                    <img class="cloth-img"
                                        :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <p>{{ item.title }}</p>
                                </li>
                            </ul>
                            <ul class="cloth" v-if="typeIndex == 1">
                                <li @click="getGoods(item, index)"
                                    :class="goodsTypeIndex == index ? 'cloth-item cloth-item-add' : 'cloth-item'"
                                    class="cloth-item" v-for="(item, index) in pants">
                                    <img class="cloth-img"
                                        :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <p>{{ item.title }}</p>
                                </li>
                            </ul>
                            <ul class="cloth" v-if="typeIndex == 2">
                                <li @click="getGoods(item, index)"
                                    :class="goodsTypeIndex == index ? 'cloth-item cloth-item-add' : 'cloth-item'"
                                    class="cloth-item" v-for="(item, index) in shoe">
                                    <img class="cloth-img"
                                        :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <p>{{ item.title }}</p>
                                </li>
                            </ul>
                            <ul class="cloth" v-if="typeIndex == 3">
                                <li @click="getGoods(item, index)"
                                    :class="goodsTypeIndex == index ? 'cloth-item cloth-item-add' : 'cloth-item'"
                                    class="cloth-item" v-for="(item, index) in home">
                                    <img class="cloth-img"
                                        :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <p>{{ item.title }}</p>
                                </li>
                            </ul>
                            <ul class="cloth" v-if="typeIndex == 4">
                                <li @click="getGoods(item, index)"
                                    :class="goodsTypeIndex == index ? 'cloth-item cloth-item-add' : 'cloth-item'"
                                    class="cloth-item" v-for="(item, index) in other">
                                    <img class="cloth-img"
                                        :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <p>{{ item.title }}</p>
                                </li>
                            </ul>
                            <ul class="cloth" v-if="typeIndex == 5">
                                <li @click="getGoods(item, index)"
                                    :class="goodsTypeIndex == index ? 'cloth-item cloth-item-add' : 'cloth-item'"
                                    class="cloth-item" v-for="(item, index) in littlecloth">
                                    <img class="cloth-img"
                                        :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <p>{{ item.title }}</p>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="拍照" name="two">
                        <div class="photoArea">
                            <div class="photoArea-left">
                                <div class="photoAreaNow">
                                    <video class="videoNow" ref="video" width="590" height="440" autoplay></video>
                                    <canvas class="canvas" ref="canvas" width="3264" height="2448"></canvas>
                                </div>
                                <div>
                                    <button class="photoArea-btn" @click="captureImage">拍照</button>
                                    <button class="photoArea-btn" style="background-color: #CCCCCC ;"
                                        @click="uploadPhotos">上传</button>
                                </div>
                            </div>
                            <div class="photoArea-right">
                                <ul class="photoArea-list">
                                    <li class="photoArea-item" style="position: relative;"
                                        v-for="(item, index) in againOssPhoto" :key="index">
                                        <button style="position: absolute; right: 0;"
                                            @click="deletePhoto(index)">x</button>
                                        <img @click="toBig(index)" :src="item" alt="">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="颜色" name="three">
                        <div class="colorList">
                            <ul>
                                <li @click="getColor(item, index)"
                                    :class="goodsColorIndex == index ? 'colorItem colorItem-add' : 'colorItem'"
                                    v-for="(item, index) in colorList" :key="index">{{ item }}</li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="附件" name="four">
                        <div class="fileList">
                            <ul>
                                <li @click="getFile(item, index)"
                                    :class="goodsFilesIndex.includes(index) ? 'fileItem fileItem-add' : 'fileItem'"
                                    v-for="(item, index) in fileList">
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="瑕疵" name="five">
                        <div class="flawList">
                            <ul>
                                <li @click="getFlaw(item, index)"
                                    :class="goodsFlawsIndex.includes(index) ? 'flawItem flawItem-add' : 'flawItem'"
                                    v-for="(item, index) in flawList">{{ item }}</li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="备注" name="seven">
                        <div class="noteArea">
                            <!-- <el-input type="textarea" placeholder="请输入备注" v-model="goodsNotes">
                            </el-input> -->
                            <textarea style="width: 1200px; height: 150px;"placeholder="请输入备注" v-model="goodsNotes"></textarea>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <canvas style="display: none;" ref="barcodeCanvas"></canvas>
            <div class="right-print" v-if="this.checkGoodsID" id="toPrint">
                
                <div style="display: inline-block;">
                    <p>补录时间:{{ entryTime }}</p>
                    <p style="margin-left: 10px;">{{ goodsInfo.number }}</p>
                    <!-- <p>{{ goodsInfo.id }}</p> -->
                    <img style="margin-left: 10px;" :src="barcodeUrl" alt="">
                    <p style="margin-left: 40px;">{{ goodsInfo.id }}</p>
                </div>
                <div style="display: inline-block;">
                    <p>[{{ goodsList.length }}-{{ checkIndex + 1 }}]</p>
                    <div>
                        <span v-if="this.factoryInfo.goodsType && this.goodsType == ''">洗护类别:{{ factoryInfo.goodsType
                            }}</span>
                        <span v-if="this.goodsType != ''">洗护类别:{{ goodsType }}</span>
                    </div>
                    <div>
                        <span v-if="this.factoryInfo.goodsColor && this.goodsColor == ''">颜色:{{ factoryInfo.goodsColor
                            }}</span>
                        <span v-if="this.goodsColor != ''">颜色:{{ goodsColor }}</span>
                    </div>
                    <div>
                        <span v-if="this.factoryInfo.goodsFiles && this.goodsFiles == ''">附件:{{ factoryInfo.goodsFiles
                            }}</span>
                        <span v-if="this.goodsFiles != ''">附件:{{ goodsFiles }}</span>
                    </div>
                    <div>
                        <span v-if="this.factoryInfo.goodsFlaw && this.goodsFlaws == ''">瑕疵:{{ factoryInfo.goodsFlaw
                            }}</span>
                        <span v-if="this.goodsFlaws.length != 0">瑕疵:{{ goodsFlaws }}</span>
                    </div>
                    <div>
                        <span v-if="this.factoryInfo.goodsNotes && this.goodsNotes == ''">备注:{{ factoryInfo.goodsNotes
                            }}</span>
                        <span v-if="this.goodsNotes != ''">备注:{{ goodsNotes }}</span>
                    </div>
                </div>
            </div>
            <div class="right-btn">
                <el-button type="primary" @click="printAndSubGoods">打印水洗唛</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import OSS from 'ali-oss';
import { VuePrintNext } from 'vue-print-next';
export default {
    name: 'subGoodsAgain',
    components: {

    },
    props: {
        indexFlag: {
            type: String
        },
    },
    data() {
        return {
            goodsId: '',
            activeName: 'one',
            goodsList: [],
            orderNum: '',
            orderStatus: '',
            checkIndex: 0,
            factoryInfo: {},
            clothType: [
                { name: '上装' },
                { name: '下装' },
                { name: '鞋类' },
                { name: '家纺' },
                { name: '其他' },
                { name: '小件衣物' }

            ],
            entryTime: '',
            goodsInfo: {},
            barcodeUrl: '',
            typeIndex: 0,
            // 分类相关
            jacket: [], //上装
            pants: [], //下装
            shoe: [], //鞋类
            home: [], //家纺
            other: [], //其他
            littlecloth: [], //小件衣物

            colorList: ['米白', '米黄', '浅灰色', '青灰色', '墨绿', '浅蓝', '天蓝', '海军蓝',
                '湖蓝', '藏青', '雪青色', '浅白', '奶白', '灰白', '黑色', '灰黑', '黑夹灰', '橙色',
                '驼色', '砖色', '花色', '格子', '黑白格', '千鸟格', '条纹', '卡通图案', '白色',
                '白夹黑', '黄色', '红色', '桃红', '粉色', '蓝色', '深蓝', '草绿', '军绿', '灰色',
                '银灰色', '棕色', '咖啡色', '卡其色', '杏色', '紫色', '紫红'],
            fileList: ['盒子', '手提袋', '尘袋', '身份卡', '挂饰', '包内遗留物', '包内胆', '包撑',
                '鞋撑', '单层帽', '毛帽边', '袖筒', '有袖内胆', '无袖内胆', '下身', '毛领', '衣领',
                '鞋带', '帽子', '另一条袖带', '另一条肩带', '领子袋', '另一条毛领', '毛边',
                '另一条毛边', '毛袖', '另一条毛袖', '内胆', '活袖', '另一条活袖', '帽绳', '腰绳',
                '底绳', '活绳', '毛球', '另一个毛球', '蝴蝶结', '另一条鞋带', '另一条鞋垫', '下摆',
                '扣子', '垫扣', '拉链头', '无'],
            flawList: ['掉扣子', '扣掉漆', '折痕', '印花开裂', '污渍', '磨损', '磨破', '变形',
                '发黄', '渍尽洗', '油渍', '泥渍', '黑渍', '笔渍', '茶渍', '白渍', '墨渍', '果渍',
                '酒渍', '黄渍', '血渍', '奶渍', '红渍', '绿渍', '有渍', '红蜡烛渍', '咖啡渍',
                '机油渍', '乌鸡渍', '油漆渍', '脏尽洗', '白渍尽去', '黄渍有印', '甩色', '搭色',
                '串色', '阴阳色', '染色', '色花', '多处搭色', '压胶', '涂层', '起毛', '起球',
                '划痕', '压胶破损', '涂层破损', '日照褪色', '褪色', '开胶', '折痕开裂', '皮面开裂',
                '皮面起泡', '霉斑', '锈迹', '纽扣掉色', '少色', '褪色', '易褪色', '光泽差', '咬色',
                '有孔', '有烂', '甩丝', '磨花', '勾线', '反光', '磨烂', '修补过', '掉绒', '蛀洞',
                '少商标', '无'],
            checkGoodsID: '',
            goodsType: '',
            goodsColor: '',
            goodsFiles: [],
            goodsFlaws: [],
            goodsNotes: [],
            goodsTypeIndex: 'xx',
            goodsColorIndex: 'xx',
            goodsFilesIndex: [],
            goodsFlawsIndex: [],
            againPhoto: [],
            againOssPhoto: [],
            showBigPhoto: false,
            bigPhotoUrl: '',
            isTime:false
        }
    },
    watch: {
        indexFlag(newValue, oldValue) {
            if (newValue == 9) {
                this.$refs.inputGoods.focus();
                this.getGoodsSelect();
            }
        },
        immediate: true,
        deep: true
    },
    mounted() {
        this.configureOSSClient();
        this.startCamera();
        this.getEntryTime();
        this.$nextTick(() => {
            window.addEventListener('keyup', (e) => {
                if (e.repeat) {
                    return;
                }
                if (e.keyCode == 13 && this.indexFlag == '9') {
                    this.searchOrder();
                } else if (e.keyCode == 27) {
                    this.showBigPhoto = false;
                } else if (e.keyCode === 75) {
                    this.captureImage();
                }
            })
        })
    },
    updated() {
        // this.getEntryTime();
        if (this.goodsInfo) {
            this.generateBarcode();
        }
    },
    computed: {

    },
    methods: {
        // 查询订单时间
        searchOrderTome(id) {
            console.log(id);
            this.$http.post('order/getOrderTracking', { order_id: id, status: '22' }, (res => {
                if (res.code == 1) {
                    let time = res.data.data.filter(item => item.statusDescription == '工厂已收货')[0].changeTime
                    let orderDate = new Date(time);
                    let timeoutDate = new Date(orderDate.getTime() + 24 * 60 * 60 * 1000);
                    let now = new Date();
                    const nowTime = now.toLocaleString();
                    let changeNow = new Date(nowTime)
                    this.isTime = changeNow > timeoutDate
                    console.log(this.isTime);
                }
            }))
        },
        // 配置oss
        configureOSSClient() {
            try {
                this.ossClient = new OSS({
                    region: 'oss-cn-beijing',
                    accessKeyId: 'LTAI5tQa4tD4qCbSVTzghqYT',
                    accessKeySecret: 'D9paNucPU5qWZDX79ZcLINd9DlgmMC',
                    bucket: 'jiekexihu'
                });
            } catch (error) {
                console.error('配置 OSS 客户端时出错:', error);
            }
        },
        // 拍照回调
        captureImage() {
            const canvas = this.$refs.canvas;
            const video = this.$refs.video;
            if (!canvas) {
                return
            } else {
                canvas.getContext('2d').drawImage(video, 0, 0, 3264, 2448);
            }
            canvas.toBlob((blob) => {
                // 创建一个 URL 来表示 Blob 对象
                const url = URL.createObjectURL(blob);

                // 将照片对象添加到 photos 数组中
                this.againPhoto.unshift({ url });
                this.uploadToOSS(blob);
            }, 'image/png');
        },
        // 上传到oss回调
        async uploadToOSS(blob) {
            if (this.againPhoto.length > 0) {
                try {
                    const fileName = `${Date.now()}`;
                    const file = new File([blob], 'image.png', { type: blob.type });
                    this.ossFile = file;
                    const result = await this.ossClient.put(fileName, this.ossFile, {
                        headers: {
                            'Content-Type': 'image/png',
                            'Content-Disposition': 'inline'
                        }
                    });
                    console.log('上传成功,OSS 图片 URL:', result.url);
                    // 把返回的oss地址存到一个数组中
                    this.againOssPhoto.push(result.url);
                    console.log(this.againOssPhoto, 'oss返回照片数组');

                } catch (error) {
                    console.log('上传失败', error);
                }
            } else {
                console.log('未拍摄商品照片');
            }
        },
        // 删除照片回调
        deletePhoto(index) {
            this.againOssPhoto.splice(index, 1);
        },
        // 放大已拍图片
        toBig(index) {
            this.showBigPhoto = true;
            this.bigPhotoUrl = this.againOssPhoto[index];
            console.log(this.bigPhotoUrl);

        },
        // 开启摄像头回调
        startCamera() {
            const video = this.$refs.video;
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({
                    video: {
                        width: { ideal: 3264 },
                        height: { ideal: 2448 },
                        frameRate: { ideal: 30, max: 30 }
                    }
                    // video:true
                })
                    .then(stream => {
                        if (!video) {
                            return
                        } else {
                            video.srcObject = stream;
                        }
                    })
                    .catch(error => {
                        console.error('摄像头访问错误:', error);
                    });
            } else {
                alert('您的浏览器不支持摄像头访问');
            }
        },
        // 选择具体商品分类
        getGoods(item, index) {
            this.goodsType = item.title;
            this.goodsTypeIndex = index;
        },
        // 选择具体商品颜色
        getColor(item, index) {
            this.goodsColor = item;
            this.goodsColorIndex = index;
            this.activeName = 'four';
        },
        // 选择具体商品附件
        getFile(item, indexs) {
            if(item == '无'){
                this.activeName = 'five';
            }
            if (this.goodsFiles.includes(item)) {
                let index = this.goodsFiles.indexOf(item);
                if (index !== -1) {
                    this.goodsFiles.splice(index, 1)
                }
            } else {
                this.goodsFiles.push(item);
            }
            if (this.goodsFilesIndex.includes(indexs)) {
                let index = this.goodsFilesIndex.indexOf(indexs);
                if (index !== -1) {
                    this.goodsFilesIndex.splice(index, 1)
                }
            } else {
                this.goodsFilesIndex.push(indexs);
            }
        },
        // 选择具体商品瑕疵
        getFlaw(item, indexs) {
            if (this.goodsFlaws.includes(item)) {
                let index = this.goodsFlaws.indexOf(item);
                if (index !== -1) {
                    this.goodsFlaws.splice(index, 1)
                }
            } else {
                this.goodsFlaws.push(item);
            }
            if (this.goodsFlawsIndex.includes(indexs)) {
                let index = this.goodsFlawsIndex.indexOf(indexs);
                if (index !== -1) {
                    this.goodsFlawsIndex.splice(index, 1)
                }
            } else {
                this.goodsFlawsIndex.push(indexs);
            }
        },
        // 上传商品照片回调
        uploadPhotos() {
            let url = this.againOssPhoto
            if (url == '') {
                this.$message({
                    message: '请先拍照片,再上传  ',
                    type: 'error'
                })
            } else {
                this.$http.post(`/rfidlist/addImage?uid=${JSON.parse(localStorage.getItem('userinfo')).id}&goodsId=${this.checkGoodsID}&photo=${url}`, null, (res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: 'success',
                            message: '上传图片成功'
                        })
                        this.capturedPhotos = [],
                            this.activeName = 'three'
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        })
                    }
                });
                this.ossUrls = []
                this.Chaibaohotos = []
            }
        },
        // 打印主水洗唛回调
        handlePrint() {
            new VuePrintNext({
                el: '#toPrint',
                 preview: false
            });
            this.searchOrder();
        },
        // 提交更新商品信息+打印商品水洗唛
        printAndSubGoods() {
            if (this.goodsType == ''  && this.goodsColor == ''
            && this.goodsFiles.length == 0 && this.goodsFlaws.length == 0) {
                this.handlePrint();
            }else if(this.goodsType == ''  || this.goodsColor == ''
                || this.goodsFiles.length == 0 || this.goodsFlaws.length == 0){
                this.$message({
                    message: '请全部更新水洗唛信息后提交',
                    type: 'error'
                })
            }else {
                let data = {
                    goodsType: this.goodsType,
                    goodsColor: this.goodsColor,
                    goodsFiles: this.goodsFiles,
                    goodsFlaw: this.goodsFlaws,
                    goodsRisk: this.factoryInfo.goodsRisk,
                    goodsNotes: this.goodsNotes,
                    goodsRecord: this.factoryInfo.goodsRecord
                };
                let params = JSON.stringify(data);
                this.$http.post('order/updateGoodsFactoryData', { ogId: this.checkGoodsID, data: params }, (res => {
                    if(res.code == 1){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.goodsType = '';
                        this.goodsColor = '';
                        this.goodsFiles = [];
                        this.goodsFlaws = [];
                        this.goodsNotes = '';
                        this.goodsTypeIndex = 'xx';
                        this.goodsColorIndex = 'xx';
                        this.goodsFilesIndex = [];
                        this.goodsFilesIndex = [];
                        this.activeName = 'one';
                        this.handlePrint();
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                }))
            }
        },
        // 获取全部商品分类
        getGoodsSelect() {
            this.$http.post('goods/select', { switch: 1, levels: [1, 2, 3], pageSize: 1000, notDisplayGoodType: 3 }, (res => {
                this.jacket = res.data.filter(item => item.goods_type_id == 1 || item.goods_type_id == 13);
                this.pants = res.data.filter(item => item.goods_type_id == 9 || item.goods_type_id == 15);
                this.shoe = res.data.filter(item => item.goods_type_id == 2 || item.goods_type_id == 16);
                this.home = res.data.filter(item => item.goods_type_id == 6);
                this.other = res.data.filter(item => item.goods_type_id == 17);
                this.littlecloth = res.data.filter(item => item.goods_type_id == 19);
            }))
        },
        // 点击分类具体类型
        getType(index) {
            this.typeIndex = index;
        },
        // 获取时间
        getEntryTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const time = now.toLocaleTimeString();
            this.entryTime = `${year}-${month}-${day} ${time}`;
        },
        // 查询
        searchOrder() {
            this.getEntryTime();
            this.getGoodsSelect();
            let uid = JSON.parse(localStorage.getItem('userinfo')).id;
            if (this.goodsId == '' && this.checkGoodsID != '') {
                let obj = {
                    ogid: this.checkGoodsID,
                    id: uid
                }
                this.$http.post('/receiving/waterWashedLabelorderInfo', obj, (res) => {
                    if (res.code == 1) {
                        if (res.data.length == 0) {
                            this.$message({
                                message: '暂无商品信息',
                                type: 'error'
                            });
                            this.goodsId = ''
                        } else {
                            this.goodsList = res.data;
                            this.goodsInfo = res.data[0];
                            this.factoryInfo = JSON.parse(res.data[0].factory_use_info)
                            this.checkGoodsID = res.data[0].id;
                            this.searchOrderTome(res.data[0].order_id);
                            this.goodsId = ''
                        }
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })
            } else {
                let obj = {
                    ogid: this.goodsId,
                    id: uid
                };
                this.$http.post('/receiving/waterWashedLabelorderInfo', obj, (res) => {
                    if (res.code == 1) {
                        if (res.data.length == 0) {
                            this.$message({
                                message: '暂无商品信息',
                                type: 'error'
                            });
                            this.goodsId = ''
                        } else {
                            this.goodsList = res.data;
                            this.goodsInfo = res.data[0];
                            this.factoryInfo = JSON.parse(res.data[0].factory_use_info)
                            this.checkGoodsID = res.data[0].id;
                            this.searchOrderTome(res.data[0].order_id);
                            this.goodsId = '';
                        }
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })
            }

        },
        // 选择商品列表
        checkGoods(index) {
            this.checkIndex = index;
            this.factoryInfo = JSON.parse(this.goodsList[index].factory_use_info);
            this.goodsInfo = this.goodsList[index];
            this.getEntryTime();
            this.checkGoodsID = this.goodsList[index].id;
            this.goodsType = '';
            this.goodsColor = '';
            this.goodsFiles = [];
            this.goodsFlaws = [];
            this.goodsNotes = '';
            this.goodsTypeIndex = 'xx';
            this.goodsColorIndex = 'xx';
            this.goodsFilesIndex = [];
            this.goodsFilesIndex = [];
            this.activeName = 'one';
        },
        generateBarcode() {
            const barcodeData = this.goodsInfo.id;
            const canvas = this.$refs.barcodeCanvas;
            if (canvas == undefined) {
                return;
            } else {
                JsBarcode(canvas, barcodeData, {
                    format: 'CODE128',
                    lineColor: '#000000',
                    width: 2,
                    height: 50,
                    displayValue: false,
                    margin: 0,
                    fontSize: 20
                });
                const dataUrl = canvas.toDataURL('image/png');
                this.barcodeUrl = dataUrl;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.big {
    display: flex;

    .left {
        width: 320px;
        height: 830px;
        background-color: #FFFFFF;
        padding: 20px;

        .left-title {
            display: flex;
            margin-bottom: 16px;

            input {
                width: 217px;
                height: 40px;
                border: 1px solid #DCDFE6;
                border-radius: 5px;
            }

            button {
                width: 71px;
                height: 40px;
                background-color: #3278FF;
                border: #FFFFFF;
                color: #FFFFFF;
                border-radius: 5px;
            }
        }

        .left-content {
            .title {
                color: #666666;
                font-size: 16px;

                span {
                    color: #3278FF;
                }
            }

            .num {
                color: #333333;
                font-size: 16px;
                margin-bottom: 16px;
            }

            .order-list {
                margin-top: 16px;

                .order-item {
                    border: 2px dotted #333333;
                    box-sizing: border-box;
                    padding: 10px;
                    width: 280px;
                    height: 104px;
                    margin-bottom: 5px;

                    p {
                        color: #666666;
                        font-size: 18px;
                        margin-bottom: 16px;
                    }
                }

                .order-item-add {
                    position: relative;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
                    border: 0;
                    width: 29 0px;
                    border-radius: 20px;
                }
            }

            .order-btn {
                position: fixed;
                top: 820px;
                width: 280px;
                height: 40px;
                font-size: 18px;
                color: #FFFFFF;
                border: #FFFFFF;
                background-color: #CCCCCC;
            }
        }
        .left-content-add{
            background-image: url(../../assets/images/timeOut.png);
            background-repeat: no-repeat;
            background-position:top right;
            background-size: 100px 100px;
        }
    }

    .right {
        width: 100%;
        height: 830px;
        background-color: #FFFFFF;

        .right-tabs {
            height: 640px;

            .sort-title {
                ul {
                    display: flex;

                    .sort-item {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        margin-top: 16px;
                        width: 76px;
                        height: 48px;
                        text-align: center;
                        line-height: 48px;
                        background-color: #F5F5F5;
                        margin-right: 16px;
                        border-radius: 24px;
                        font-size: 18px;
                    }

                    .sort-item-add {
                        background-color: #3278FF;
                        color: #FFFFFF;
                    }
                }
            }

            .photoArea {
                padding: 24px;
                height: 555px;
                display: flex;

                .photoArea-left {
                    width: 625px;

                    div {
                        display: flex;

                        .photoArea-btn {
                            margin-top: 24px;
                            margin-right: 10px;
                            width: 290px;
                            height: 48px;
                            background: #3278FF;
                            border-radius: 4px;
                            border: 0;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            font-size: 18px;
                            color: #FFFFFF;
                            line-height: 18px;
                            text-align: center;
                            font-style: normal;
                        }
                    }

                }

                .photoArea-right {
                    width: 680px;
                    height: 520px;
                    overflow: auto;

                    .photoArea-list {
                        display: flex;
                        flex-wrap: wrap;

                        .photoArea-item {
                            width: 160px;
                            height: 119px;
                            background-color: #666666;
                            margin-right: 16px;
                            margin-bottom: 16px;

                            img {
                                width: 160px;
                                height: 119px;
                            }
                        }
                    }
                }
            }

            .colorList {
                height: 550px;
                padding: 24px;

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    .colorItem {
                        width: 76px;
                        height: 48px;
                        background-color: #F5F5F5;
                        font-weight: 500;
                        font-size: 18px;
                        color: #666666;
                        line-height: 48px;
                        text-align: center;
                        font-style: normal;
                        border-radius: 24px;
                        margin-right: 16px;
                        margin-top: 16px;
                    }

                    .colorItem-add {
                        background-color: #0486FE;
                        color: #FFFFFF;
                    }
                }
            }

            .fileList {
                height: 550px;
                padding: 24px;

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    .fileItem {
                        width: 110px;
                        height: 48px;
                        background-color: #F5F5F5;
                        font-weight: 500;
                        font-size: 18px;
                        color: #666666;
                        line-height: 48px;
                        text-align: center;
                        font-style: normal;
                        border-radius: 24px;
                        margin-right: 16px;
                        margin-top: 16px;
                    }

                    .fileItem-add {
                        background-color: #0486FE;
                        color: #FFFFFF;
                    }
                }
            }

            .flawList {
                height: 550px;
                padding: 24px;
                overflow: auto;

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    .flawItem {
                        width: 110px;
                        height: 48px;
                        background-color: #F5F5F5;
                        font-weight: 500;
                        font-size: 18px;
                        color: #666666;
                        line-height: 48px;
                        text-align: center;
                        font-style: normal;
                        border-radius: 24px;
                        margin-right: 16px;
                        margin-top: 16px;
                    }

                    .flawItem-add {
                        background-color: #0486FE;
                        color: #FFFFFF;
                    }
                }
            }

            .noteArea {
                height: 550px;
                padding: 24px;
            }

            .cloth {
                height: 490px;
                text-align: center;
                overflow: auto;

                ul {

                    display: flex;
                    flex-wrap: wrap;

                    .cloth-item {
                        width: 88px;
                        height: 140px;
                        margin-right: 8px;
                        margin-top: 10px;
                        font-size: 16px;
                        background-color: #F0F0F0;

                        .cloth-img {
                            width: 88px;
                            height: 88px;
                        }
                    }

                    .cloth-item-add {
                        border: 2px solid #3278FF;
                        background: rgba(50, 120, 255, 0);
                        border-radius: 4px;
                    }
                }
            }
        }

        .right-print {
            height: 190px;
            width: 1200px;
        }

        .right-btn {
            position: absolute;
            left: 1600px;
            top: 640px;
        }
    }
}
</style>