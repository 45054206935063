<template>
	<div class="container">
		<el-container class="container">
			<el-header style="margin-left: -20px; height: 40px;">
				<Heads></Heads>
			</el-header>
			<el-main>
				<div class="content">
					<el-tabs tab-position="left" class="tab" type="card" @tab-click="tabs">
						<el-tab-pane label="工作台" style="height: 100%;">
							<work :selecttab="selecttab"></work>
						</el-tab-pane>
						<!-- <el-tab-pane label="全部" style="height: 100%;overflow: hidden;">
							<allOrder :orderId="orderId" :selecttab="selecttab" :authid="authid"></allOrder>
						</el-tab-pane> -->
						<el-tab-pane label="入厂" style="height: 100%;overflow: hidden;">
							<enterFactory :indexFlag="indexFlag"></enterFactory>
						</el-tab-pane>
						<el-tab-pane label="拆包" style="height: 100%;">
							<unpacking :indexFlag="indexFlag"></unpacking>
						</el-tab-pane>
						<el-tab-pane label="流转" style="height: 100%;">
							<convert :indexFlag="indexFlag"></convert>
						</el-tab-pane>
						<!-- <el-tab-pane label="拆包" style="height: 100%;overflow: hidden;"
							v-if="authid == '1' || authid == '2'">
							<receiving @clearAll="getclearAll" :allPopNums="allPopNums" :goodsPopNums="goodsPopNums" :isVIP="isVIP" :newRfid="newRfid" :newOrderId="newOrderId" @gerOrdrList="getOrderAgain" @rfid="getRfid" :indexFlag="indexFlag" :selecttab="selecttab" :authid="authid"
								:Recivelist="Recivelist" :reciveimage="imageList" :bigRfid="bigRfid" :refresh="refresh"
								@delindex="removeindex">
							</receiving>
						</el-tab-pane> -->
						<el-tab-pane label="拒收" style="height: 100%;" v-if="authid == '1' || authid == '3'">
							<rejection :selecttab="selecttab" :authid="authid"></rejection>
						</el-tab-pane>
						<el-tab-pane label="质检" style="height: 100%;" v-if="authid == '3'">
							<inspection :authid="authid" :Inspectionlist="Inspectionlist" :zhijianlist="zhijianlist"
								:refresh="refreshs">
							</inspection>
						</el-tab-pane>
						<el-tab-pane label="补录" style="height: 100%;">
							<!-- <subGoodsAgain :indexFlag="indexFlag"></subGoodsAgain> -->
							<sendAgain @rfid="getRfid" :indexFlag="indexFlag" :selecttab="selecttab" :authid="authid"
								:Recivelist="Recivelist" :reciveimage="imageList" :bigRfid="bigRfid" :refresh="refresh"
								@delindex="removeindex"></sendAgain>
						</el-tab-pane>
						<el-tab-pane label="品管" style="height: 100%;">
							<searchGoods :indexFlag="indexFlag"></searchGoods>
						</el-tab-pane>
						<el-tab-pane label="上挂" style="height: 100%;">
							<printStick :indexFlag="indexFlag"></printStick>
						</el-tab-pane>
						<el-tab-pane label="组单" style="height: 100%;">
							<afterWash :afterWashlist="afterWashlist" :selecttab="selecttab" :authid="authid">
							</afterWash>
						</el-tab-pane>
						<!-- <el-tab-pane label="查询" style="height: 100%;">
							<searchInfo></searchInfo>
						</el-tab-pane> -->
						<!-- <el-tab-pane label="出厂" style="height: 100%;">
							<outFactory></outFactory>
						</el-tab-pane>	 -->
						<!-- <el-tab-pane label="新补录" style="height: 100%;">
							<subGoodsAgain :indexFlag="indexFlag"></subGoodsAgain>
						</el-tab-pane> -->
					</el-tabs>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import Heads from '../../components/index/header.vue'
import work from '../../components/index/work.vue'
import receiving from '../../components/index/receiving.vue'
import rejection from '../../components/index/rejection.vue'
import inspection from '../../components/index/inspection.vue'
import allOrder from '../../components/index/allOrder.vue'
import afterWash from '../../components/index/afterWash.vue'
import printStick from '../../components/index/printStick.vue'
import sendAgain from '../../components/index/sendAgain.vue'
import searchInfo from '../../components/index/searchInfo.vue'
import enterFactory from '../../components/index/enterFactory.vue'
import unpacking from '../../components/index/unpacking.vue'
import convert from '../../components/index/convert.vue'
import searchGoods from '../../components/index/searchGoods.vue'
import outFactory from '../../components/index/outFactory.vue'
import subGoodsAgain from '../../components/index/subGoodsAgain.vue'
import $ from 'jquery'
export default {
	name: 'home',
	components: {
		Heads,
		work,
		receiving,
		rejection,
		inspection,
		allOrder,
		afterWash,
		printStick,
		sendAgain,
		searchInfo,
		enterFactory,
		unpacking,
		convert,
		searchGoods,
		outFactory,
		subGoodsAgain
	},
	data: function () {
		return {
			orderId: '',
			authid: '',
			selecttab: '',
			Recivelist: [],
			imageList: {},
			Inspectionlist: [],
			zhijianlist: [],
			flowMode: '',
			bigRfid: '',
			refresh: false,
			refreshs: false,
			afterWashlist: [],
			indexFlag: '',
			againRfid: '',
			newOrderId:'',
			newRfid:'',
			isVIP:'',
			allPopNums:'',
			goodsPopNums:''
		}
	},
	created() {
		this.authid = JSON.parse(localStorage.getItem('userinfo')).authid
		console.log(this.authid, 'index')
		// this.getWebSocket()
	},
	mounted() {
		document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';
		const that = this
		window.onresize = () => {
			return (() => {
				window.screenWidth = document.body.clientWidth
				document.querySelector('.container').style.height = document.documentElement.clientHeight + 'px';

			})()
		}
		this.$nextTick(() => {
			window.addEventListener('keyup', (e) => {
				if (e.repeat) {
					return;
				}
				if (e.keyCode == 13) {
					// if (this.indexFlag == '2') {
					// 	// this.onInputOrderId(this.orderId);
					// 	console.log(this.indexFlag);
					// }
				}
				// this.orderId += String.fromCharCode(e.keyCode);
			})
		})
	},
	methods: {
		getclearAll(msg){
			this.Recivelist = [],
			this.isVIP = '';
		},
		getOrderAgain(msg){
			this.onInputOrderId(msg);
		},
		getRfid(rfid) {
			// this.againRfid = rfid;
			// console.log('onInputOrderId刷新执行', this.againRfid);
			// let uid = JSON.parse(localStorage.getItem('userinfo')).id;
			// this.$http.post(`/rfidlist/checkRfid?recvRfid=${this.againRfid}&uid=${uid}`, null, (res) => {
			// 	console.log('请求发送成功', res.data, '---------------');
			// 	this.onGetOrder(res.data);
			// });
			// this.orderId = '';
		},
		async tabs(e) {
			console.log(e.index)
			if(e.index == 2){
				this.orderId = '';
			}
			this.indexFlag = e.index;
			this.selecttab = e.index
			if (e.index == '8') {
				let obj = {
					status: '24'
				};
				await this.$http.post('/index/getOrderList', obj, (res) => {
					this.afterWashlist = res.data.list;
				})
			}
		},
		removeindex(index) {
			this.Recivelist.splice(index, 1);
		},
		onInputOrderId(orderRfid) {
			console.log('onInputOrderId执行', orderRfid);
			this.$http.post('order/getByTransferCode',{transferCode:orderRfid},(res => {
				if(res.code == 1){
					this.isVIP = res.data.is_vip;
					this.allPopNums = res.data.userCard.nums;
					this.goodsPopNums = res.data.goodsList.reduce((acc, curr) => {
  						return acc + Number(curr.card_nums);
					}, 0);
					this.Recivelist = [];
					let number = res.data.number;
					let rfid = res.data.rfid;
					let orderTime = res.data.createtime;
					let order_id = res.data.id;
					let mobile = res.data.address.contact_number;
					// let mobile = '';
					// let city = '';
					// if(res.data.address != null){
					// 	mobile = res.data.address.contact_number;
					// 	city = res.data.address.street_address;
					// }else{
					// 	mobile = '数微订单';
					// 	city = '数微订单';
					// }
					let content = res.data.content;
					let content2 = res.data.content2;
					this.newOrderId = order_id;
					this.newRfid = orderRfid;
					const newArray = res.data.goodsList.map(item => ({...item,number,orderTime,order_id,content,mobile,content2,rfid}));					
					this.Recivelist = newArray;
				}else{
					this.$message({
                        type: 'info',
                        message: '无效'
                    })
				}
			}))
			this.orderId = '';
		},
		onGetOrder(data) {
			console.log(111, data)
			let self = this;
			var type = data.type || '';
			console.log(type)
			self.refresh = false
			self.refreshs = false
			switch (type) {
				// Events.php中返回的init类型的消息，将client_id发给后台进行uid绑定
				case 'init':

					// 利用jquery发起ajax请求，将client_id发给后端进行uid绑定
					// $.post('./bind.php', {client_id: data.client_id}, function(data){}, 'json');
					self.$http.post('rfidlist/getclientid', { user_id: localStorage.getItem('id'), client_id: data.client_id }, (res) => {
					})
					localStorage.setItem('clientId', data.client_id)
					if (JSON.parse(localStorage.getItem('userinfo')).authid == '1') {
						$.getJSON('http://127.0.0.1:19685/api/card/beginQc?uid=' + localStorage.getItem('id'), function (data) {
							// eval(data);
							console.log(data)
						});
					}
					if (JSON.parse(localStorage.getItem('userinfo')).authid == '2') {
						$.getJSON('http://127.0.0.1:19685/api/card/beginBag?uid=' + localStorage.getItem('id'), function (data) {
							// eval(data);
							console.log(data)
						});
					}
					if (JSON.parse(localStorage.getItem('userinfo')).authid == '3') {
						$.getJSON('http://127.0.0.1:19685/api/card/beginStockOut?uid=' + localStorage.getItem('id'), function (data) {
							// eval(data);
							console.log(data)
						});
					}
					break;
				case 'success':
					
					if (self.selecttab == '3') {
						self.Recivelist = data.data
					} else if (self.selecttab == '2') {
						self.Recivelist = data.data
					} else {
						self.$message.error('请点击到收件页面进行扫码111')

						console.log(self.Recivelist)
					}
					break;
				case 'success2':
					if (self.selecttab !== '3') {
						self.$message.error('请点击到收件页面进行扫码')
					} else {
						self.Recivelist.unshift(JSON.parse(e.data).data)
						console.log(self.Recivelist)
					}
					break;
				case 'success3':
					console.log(self.selecttab)
					if (self.selecttab !== '3') {
						self.$message.error('请点击到质检页面进行扫码')
					} else {
						self.Inspectionlist = JSON.parse(e.data).data
						console.log(self.Inspectionlist)
					}
					break
				case 'error':
					self.$message.info(data.message)
					break;
				case 'error2':
					if (self.selecttab !== '3') {
						self.$message.error('请点击到收件页面进行扫码')
					} else {
						self.$message.info(data.message)
						self.bigRfid = data.data.rfid
						// localStorage.setItem('bigRfidMax', data.data.rfid)

						if (localStorage.getItem('bigRfid')) {
							if (localStorage.getItem('bigRfid') !== data.data.rfid) {
								localStorage.setItem('bigRfid1', data.data.rfid)
							}
						} else {
							localStorage.setItem('bigRfid', data.data.rfid)
						}
						var smallRfid = [];
						if (self.Recivelist.length > 0) {
							const seen = new Set();
							self.Recivelist.forEach(item => {
								const key = JSON.stringify(item);
								if (!seen.has(key)) {
									seen.add(key);
									smallRfid.push(item);
								}
							});
						}
						console.log(smallRfid)
						var str = ''
						if (smallRfid.length > 0) {
							str = smallRfid.map(function (elem) {
								return elem.id;
							}).join(",");
						}

						console.log(str)
						self.$http.post('rfidlist/bindGigRfid', { uid: localStorage.getItem('id'), bagRfid: localStorage.getItem('bigRfid'), qcRfid: str }, (res) => {
							if (res.code == 1) {
								if (str !== '' && localStorage.getItem('bigRfid1')) {
									localStorage.setItem('bigRfid', localStorage.getItem('bigRfid1'))
									localStorage.removeItem('bigRfid1')
								}

							} else {
								// self.$message.error(res.msg)
							}

						})
						self.Recivelist = []
					}

					break;
				case 'bindimage':
					// self.$message.success('绑定成功')
					console.log(JSON.parse(e.data))
					self.zhijianlist = JSON.parse(e.data).data.image
					console.log(self.zhijianlist)
					// self.zhijianlist = 
					break;
				case 'refresh':
					console.log(e.data)
					console.log(JSON.parse(e.data))
					self.refresh = true
					console.log(self.refresh)
					break;
				case 'bindqau':
					self.$message.success('绑定成功')
					self.refreshs = true
					break;
				default:
					console.log(data)
					self.imageList = data
			}
		},
		// getWebSocket() {
		// 	// 'ws://'+
		// 	var ws = new WebSocket(localStorage.getItem('httpip'));
		// 	// var ws = new WebSocket('wss://xcx.jiekee.com/wss');
		// 	// +localStorage.getItem('httpip')

		// 	ws.onopen = function (event) {
		// 		console.log('WebSocket 连接已打开');
		// 	};

		// 	ws.onmessage = (e) => {
		// 		try {
		// 			console.log(e);
		// 			var data = JSON.parse(e.data);
		// 			this.onGetOrder(data);
		// 		} catch (error) {
		// 			console.log(error);
		// 		}
		// 	}
		// }
	},
}
</script>

<style>
.container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.el-header {
	color: #333;
}

.el-main {
	color: #333;
	/* padding: 20px 80px !important; */
	background: #eee;
	margin-left: -15px;
}

.content {
	height: 100%;
}

.tab {
	/* background: #fff; */
	height: 100%;
}

.el-tabs__header {
	background: #fff;
	height: 100%;
}

.el-tabs__item {
	border: none !important;
	height: 78px !important;
	line-height: 78px !important;
	width: 150px !important;
	font-size: 24px !important;
	text-align: center !important;
}

.el-tabs__item.is-active {
	background: #EAF1FF;
	color: #333 !important;
}

.el-tabs__nav {
	border: none !important;
}

.el-tabs__content {
	/* background: #ddd; */
	height: 100%;
	padding: 0 16px !important;
}

/* 隐藏扫码枪输入框 */
/* .input_content{
	height: 0;
	margin-left: 30px;
} */
</style>