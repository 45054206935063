<template>
    <div>
        <div class="afterWash">
            <el-container style="height: 810px; border: 1px solid #eee">
                <el-aside width="300px" style="background-color: white">
                    <el-menu :default-openeds="['1', '3']">
                        <el-submenu index="1">
                            <template slot="title">清洗完成订单:共({{ afterWashlist.length }})件</template>
                            <el-menu-item-group>
                                <el-menu-item @click="toOrderInfo(item)" v-for="(item, index) in afterWashlist"
                                    :key="index">订单编号:{{ item.number
                                }}</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </el-menu>
                </el-aside>
                <div class="orderInfo">
                    <div v-if="orderList.length > 0" :class="isTime ? 'orderInfo-show' : ''">
                        <div class="order_basic_top">订单基本信息</div>
                        <ul style="margin-bottom: 50px;" class="basic_content" v-for="(item,index) in orderList">
                            <li>订单编号:{{ item.number }}</li>
                            <li>订单时间:{{ item.createtime }}</li>
                            <li>商品ID:{{ item.id }}</li>
                            <li>商品类型:{{ item.type_title }}</li>
                            <li style="width: 282px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis">商品名称:{{ item.title }}</li>
                            <li>电话:{{ item.mobile }}</li>
                            <li>地区:{{ item.city }}</li>
                            <!-- <li>是否加急:{{ item.urgent == 2 ? "是" : "否" }}</li> -->
                        </ul>
                        <div>
                            <p style="margin-bottom: 30px;">打印配送单预览:</p>
                            <canvas style="display: none;" ref="sendCode"></canvas>
                            <div ref="printSend">
                                <div style="border-bottom: 1px solid black;  margin-left: 40px;">
                                    <img style="width: 300px; height: 50px; margin-bottom: 5px;" :src="codeImgUrl" alt="">
                                </div>
                                <div>
                                    <ul style="list-style: none;">
                                        <!-- <li>订单编号:{{ orderList[0].number }}</li> -->
                                        <li style="font-size: 30px;">{{ orderList[0].cabinet_group_no }}{{orderList[0].cabinet_name}}({{ orderList[0].cabinet_id }})</li>
                                    </ul>
                                </div>
                                <div style="margin-left: 40px; border-top: 1px solid black;">订单信息:</div>
                                <ul style="display: inline-block; margin-right: 10px; list-style: none;">
                                    <li>商品</li>
                                    <li v-for="(item,index) in orderList" :key="index" style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.title }}</li>
                                </ul>
                                <ul style="display: inline-block; margin-right: 10px; list-style: none;">
                                    <li>数量</li>
                                    <li v-for="(item,index) in orderList" :key="index">1</li>
                                </ul>
                                <ul style="display: inline-block; list-style: none;">
                                    <li>水洗唛编号</li>
                                    <li v-for="(item,index) in orderList" :key="index">{{ item.id }}</li>
                                </ul>
                                <div style="margin-left: 40px; border-top: 1px solid black;">客户信息:</div>
                                <ul style="list-style: none;">
                                    <li>姓名:{{ orderList[0].nickname }}</li>
                                    <li>电话:{{ orderList[0].mobile }}</li>
                                    <!-- <li>地址:{{ orderList[0].address }}</li> -->
                                    <li>存柜时间:{{ orderList[0].cabinet_createtime }}</li>
                                </ul>
                            </div>
                            <el-button @click="toPrint" style="margin-top: 20px; margin-bottom: 500px;" type="primary">点击打印</el-button>
                        </div>
                    </div>
                    <el-empty style="height: 900px;" v-else description="请选择您要查询的订单"></el-empty>
                </div>
            </el-container>
        </div>
    </div>
</template>

<script>
import order from "./order.vue";
import orderInfo from "./orderInfo.vue";
export default {
    name: "afterWash",
    components: {
        order,
        orderInfo,
    },
    props: {
        afterWashlist: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            orderList:[],
            codeImgUrl:'',
            isTime:false
        };
    },
    mounted(){
        // console.log(111);
        this.$nextTick(() => {
            if(this.orderId == undefined){
                return;
            }else{
                this.toOrderInfo(this.orderId);
            }
        })
    },
    updated(){
        this.generateBarcode();
    },
    computed: {},
    methods: {
        searchOrderTome(id) {
            console.log(id);
            this.$http.post('order/getOrderTracking', { order_id: id, status: '22' }, (res => {
                if (res.code == 1) {
                    let time = res.data.data.filter(item => item.statusDescription == '工厂已收货')[0].changeTime
                    let orderDate = new Date(time);
                    let timeoutDate = new Date(orderDate.getTime() + 24 * 60 * 60 * 1000);
                    let now = new Date();
                    const nowTime = now.toLocaleString();
                    let changeNow = new Date(nowTime)
                    this.isTime = changeNow > timeoutDate
                    console.log(this.isTime);
                }
            }))
        },
        // 点击打印回调
        toPrint(){
            setTimeout(() => {
                const printWindow = window.open('', '_blank');
                printWindow.document.open();
                printWindow.document.write('<html><head><title>Print</title></head><body>');
                printWindow.document.write('<div>' + this.$refs.printSend.innerHTML + '</div>');
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
            }, 500)
        },
        // 生成条码回
        generateBarcode() {
            if(this.orderList.length){
                const barcodeData = this.orderList[0].number;
                const canvas = this.$refs.sendCode;
                if(canvas == undefined){
                    return;
                }else{
                    JsBarcode(canvas, barcodeData, {
                        format: 'CODE128',
                        lineColor: '#000000',
                        width: 1,
                        height: 25,
                        displayValue: true,
                        margin: 0,
                        fontSize: 16
                    });
                    this.codeImgUrl = canvas.toDataURL('image/png');
                }
            }
        },
        // 点击每个订单查询详情回调
        toOrderInfo(item) {
            let obj = {
                id: item.id,
            };
            this.$http.post("/index/getOrderDetail", obj, (res) => {
                this.orderList = res.data.list;
                this.searchOrderTome(res.data.list[0].orderId)
                // this.searchOrderTome(res.data.list[0])
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.orderInfo {
    width: 500px;
    height: 810px;
    background-color: white;
}
.orderInfo-show{
    background-image: url(../../assets/images/timeOut.png);
    background-repeat: no-repeat;
    background-position: 1200px 80px;
    background-size: 160px 160px;
}
.basic_content {
    display: flex;
    flex-flow: row wrap;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 0.1875rem;
    color: #191919;
}

.basic_content li {
    width: 25%;
    height: .5rem;
    line-height: 0.5rem;
    padding: 0 .125rem;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
</style>
