/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { Message} from 'element-ui';
import {Loading} from 'element-ui';
import cache from '@/utils/cache'

let   loadingInstance = null
if (process.env.NODE_ENV == 'development') {    
	// axios.defaults.baseURL = 'https://xcx.jiekee.com/factory'
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
	
} else if (process.env.NODE_ENV == 'production') {
    // axios.defaults.baseURL = 'https://xcx.jiekee.com/factory';
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
}
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

//1. 创建新的axios实例，
// axios.create({
//   // 公共接口--这里注意后面会讲
//   baseURL: process.env.VUE_APP_BASE_URL,
//   // 超时时间 单位是ms，这里设置了3s的超时时间
//   timeout: 3 * 1000
// })
// 2.请求拦截器
axios.interceptors.request.use((config) => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  //  config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
   const requestObj = {
	  url: config.url,
	  data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
	  time: new Date().getTime()
	}
   config.headers = {
     'Authorization':'appCode 1502584078c720d58a16f0987e336c97' //配置请求头
   }
   const sessionObj = cache.session.getJSON('sessionObj')
		// console.log(sessionObj,'sessionObj')
	if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
	  cache.session.setJSON('sessionObj', requestObj)
	} else {
	  const s_url = sessionObj.url;                  // 请求地址
	  const s_data = sessionObj.data;                // 请求数据
	  const s_time = sessionObj.time;                // 请求时间
	  const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
	  // console.log(s_data === requestObj.data &&requestObj.time - s_time < interval && s_url === requestObj.url,'getjson')
	  // console.log(s_data === requestObj.data ,requestObj.time - s_time < interval , s_url === requestObj.url,'lllllll')
	  if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
	    const message = '数据正在处理，请勿重复提交';
	    console.warn(`[${s_url}]: ` + message)
	    return Promise.reject(new Error(message))
	  } else {
	    cache.session.setJSON('sessionObj', requestObj)
	  }
	}
	
	
	return config;
  //  //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  //  const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
  //  if(token){
  //     config.params = {'token':token} //如果要求携带在参数中
  //     config.headers.token= token; //如果要求携带在请求头中
  //   }
  // return config
}, error => {
  Promise.reject(error)
})

axios.interceptors.response.use((response) => {
	// 2xx 范围内的状态码都会触发该函数。
	// loadingInstance.close()
	// console.log("接口请求之后")
	return response;
}, (error) => {
	// 对响应错误做点什么
	return Promise.reject(error);
});
const http = {
	// 设置请求时间  和 headers
	// headers:{'X-Requested-With': 'XMLHttpRequest'},
	// {timeout: 5000},
	get(url,  data = {}, callback) {
		// if(url == "goods/select"){ 
		// 	axios.defaults.baseURL = "https://server.test.jiekee.com/api";
		// }
		return axios.get(url, data).then((res) => {
				// Message.error("1111");
				if (res.status == "200") {
					callback(res.data)
					return res.data
				} else if (res.data.stauts == "20001") {
					// 清本地存储    退出登录页
					// localStorage.removeItem('token');
					// Message.error(res.data.message);
					setTimeout(() => {
						router.push('/');
					}, 2000);
				} else if (res.data.stauts == "10001") {
					// 网络异常    调异常页面或404页面
					// Message.error(res.data.message);
				} else {
					// Message.error(res.data.message);
				}
			})
			.catch((err) => {
				console.log(err, '999')
			})
	},

	post(url,data = {}, callback) {
			if (process.env.NODE_ENV == 'development') {    
				// axios.defaults.baseURL = 'https://xcx.jiekee.com/factory'
				axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
			} else if (process.env.NODE_ENV == 'production') {
				// axios.defaults.baseURL = 'https://xcx.jiekee.com/factory';
				axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
			}
			if(url == 'goods/select'){
				// 正式
				// axios.defaults.baseURL = "/api";
				// 测试
				axios.defaults.baseURL = "https://server.test.jiekee.com/api";
				// 正式本地
				// axios.defaults.baseURL = "https://server.jiekee.com/api";
				// 预发布
				// axios.defaults.baseURL = "https://server.bj-j-1.jiekee.com/api";
				// axios.defaults.baseURL = "http://182.92.71.190:31234/api";
			}
		var that = this;
		// axios.defaults.headers['AuthorizationToken'] = localStorage.getItem('token');
		// axios.defaults.headers['applicationChannel'] = 'ADMIN';
		// axios.defaults.headers['loginType'] = localStorage.getItem('loginType');
		axios.post(url, data).then((res) => {
				if (res.status == "200") {
					callback(res.data)
					return res.data
				} else if (res.status == "20001") {
					// 清本地存储    退出登录页
					localStorage.removeItem('username');
					localStorage.clear();
					if(res.data.message !== null && res.data.message !== ''){
						Message.error(res.data.message);
					}else{
						Message.error(res.data.error);
					}
					setTimeout(() => {
						router.push('/');
						localStorage.clear();
					}, 1000);
				} else if (res.status == "10001") {
					// 网络异常    调异常页面或404页面
					if(res.data.message !== null && res.data.message !== ''){
						Message.error(res.data.message);
					}else{
						Message.error(res.data.error);
					}
				} else if (res.status == "500") {
					callback(res)
					if(res.data.message !== null && res.data.message !== ''){
						Message.error(res.data.message);
					}else{
						Message.error(res.data.error);
					}
					return res.data
				}else{
					if(res.data.message !== null && res.data.message !== ''){
						Message.error(res.data.message);
					}else{
						Message.error(res.data.error);
					}
					callback(res)
					return res.data
				}
			})
			.catch((err) => {
				var data = {
					data:{
						state:'11111'
					}
				}
				callback(data)
				console.log(data,err, '接口错误111')
			})
	},
}
export default http