<template>
    <div class="big">
        <div class="left">
            <div class="left-top">
                <el-input ref="inputRef" v-model="Rfid" placeholder="请扫描或输入中转码"></el-input>
                <el-button type="primary" @click="searchOrder">入厂</el-button>
            </div>
            <div class="left-content">
                <div v-if="orderNumber">
                    <p style="margin-bottom: 5px;">订单编号:</p>
                    <div class="left-num"><p>{{ orderNumber}}</p></div>
                    <p style="margin-bottom: 20px;">中转码:</p>
                    <div class="left-rfid">{{ orderRfid}}</div>
                </div>
                <div v-else>
                    <el-empty description="暂无订单"></el-empty>
                </div>
            </div>
        </div>
        <div class="right">
            <!-- <p style="margin-bottom: 20px;">已入厂(<span style="font-size: 40px; color: red;">{{ listNum }}</span>)件:</p> -->
            <div class="right-title">
                &nbsp;&nbsp;&nbsp;&nbsp;入厂数量:<span>{{ listNum }}</span>件
            </div>
            <el-table :data="tableData" border height="730px" style="width: 100%">
                <el-table-column prop="recv_time" label="入厂日期" width="300">
                    <template slot-scope="scope">
                        {{ scope.row.recv_time*1000 | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="number" label="订单编号" width="350"></el-table-column>
                <el-table-column prop="recv_user" label="入厂操作员ID" width="280"></el-table-column>
                <el-table-column prop="rfid" label="中转码" width="350"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {

    },
    props: {
        indexFlag: {
            type: String
        },
    },
    filters: {
        formatDate(value) {
            const date = new Date(value);
            return date.toLocaleString();
        }
    },
    data() {
        return {
            Rfid: '',
            indexNum: '',
            orderNumber: '',
            orderRfid: '',
            tableData: [],
            listNum:''
        }
    },
    computed: {

    },
    mounted() {
        this.getOrderList();
        this.$nextTick(() => {
            window.addEventListener('keyup', (e) => {
                if (e.repeat) {
                    return;
                }
                if (e.keyCode == 13) {
                    this.searchOrder();
                }
                // this.orderId += String.fromCharCode(e.keyCode);
            })
        })
    },
    watch: {
        indexFlag(newValue, oldValue) {
            this.indexNum = newValue;
            this.$refs.inputRef.focus();
            if (newValue == 1) {
                this.getOrderList();
            }
        },
        immediate: true,
        deep: true
    },
    methods: {
        searchOrder() {
            if(this.indexNum == 1){
                let uid = JSON.parse(localStorage.getItem('userinfo')).id;
                this.$http.post(`/rfidlist/checkRfid?recvRfid=${this.Rfid}&uid=${uid}`,null,(res => {
                    console.log(res,'接口返回结果');
                    if(res.code == 1){
                        if(res.data.type == 'success'){
                            this.orderNumber = res.data.data[0].number;
                            this.orderRfid = res.data.data[0].rfid;
                            this.getOrderList();
                        }else{
                            this.orderNumber = res.data.number;
                            this.orderRfid = res.data.rfid;
                            this.getOrderList();
                        }
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                }))
                this.Rfid = '';
            }
        },
        getOrderList() {
            let obj = {
                id: localStorage.getItem('id'),
                status: '22'
            }
            this.$http.post('/order/list', obj, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.tableData = res.data.list;
                    this.listNum = res.data.total;
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.big {
    display: flex;

    .left {
        background-color: white;
        width: 368px;
        padding: 20px;

        .left-top {
            display: flex;
            justify-content: space-between;
        }

        .left-content {
            margin-top: 24px;
            div {
                p{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 18px;
                    color: #666666;
                    line-height: 18px;
                    text-align: left;
                    font-style: normal;
                }
                .left-num{
                    margin-bottom: 32px;
                    margin-top: 8px;
                    width: 290px;
                    p{
                        height: 64px;
                        width: 288px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        font-size: 32px;
                        color: #3278FF;
                        line-height: 32px;
                        text-align: left;
                        font-style: normal;
                        overflow-wrap: break-word;
                    }
                }
                .left-rfid{
                    overflow-wrap: break-word;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 32px;
                    color: #3278FF;
                    line-height: 32px;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
    }

    .right {
        width: 100%;
        margin-left: 16px;
        padding: 20px;
        background-color: #FFFFFF;
        .right-title{
            height: 64px;
            width: 296px;
            text-align: center;
            background-color: #EAF1FF;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            line-height: 64px;
            text-align: left;
            font-style: normal;
            margin-bottom: 20px;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 32px;
                color: #3278FF;
                line-height: 32px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
</style>